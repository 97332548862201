import styled from 'styled-components';

export const TreeGridToolbarContainerStyled = styled.div`
  display: flex;
  width: auto;

  .e-toolbar .e-tbar-btn {
    border-radius: 12px !important;
    margin-bottom: 6px !important;

    &:hover {
      background-color: #F5F5F5 !important;
    }
  }

  .e-tbar-btn-text {
    color: var(--basic) !important;
    /* font-size: 13px !important; */
  }

  & > button {
    margin-right: 10px;
  }
`;
