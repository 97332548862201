import React from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import { handleExpandLevel } from './handleExpandLevel';
import { TreeGridToolbarContainerStyled } from './styles';
import PropTypes from 'prop-types';
import { Separator } from '../../kanban/styles';

export const ToolbarTemplate = ({navData, instance}) => {
  const toolbarItems = [
    { id: 'ExpandAll', prefixIcon: 'e-icons e-expand', text: 'Expand All', tooltipText: 'Expand All Nodes' },
    { id: 'CollapseAll', prefixIcon: 'e-icons e-collapse-2', text: 'Collapse All', tooltipText: 'Collapse All Nodes' },
    { id: '1', text: 'Level 1', tooltipText: 'Expand to Level 1' },
    { id: '2', text: 'Level 2', tooltipText: 'Expand to Level 2' },
    { id: '3', text: 'Level 3', tooltipText: 'Expand to Level 3' },
  ];

  const handleToolbarClick = (args) => {
    const { id } = args.item;
    handleExpandLevel(instance, id);
  };

  return (
    <TreeGridToolbarContainerStyled>
      <ToolbarComponent
        items={toolbarItems}
        overflowMode="Extended"
        clicked={handleToolbarClick}
      >
        <ItemsDirective>
          {toolbarItems.map(({ id, text, prefixIcon, tooltipText }) => (
            <ItemDirective
              key={id}
              id={id}
              text={text}
              prefixIcon={prefixIcon}
              tooltipText={tooltipText}
            />
          ))}
        </ItemsDirective>
      </ToolbarComponent>
      <Separator>|</Separator>
      {navData.widgetData.menu ? (
        <MenuBar
          key={`menu-${navData.widgetData.menu.id}`}
          menu={navData.widgetData.menu}
        />
      ) : null}
    </TreeGridToolbarContainerStyled>
  );
};

ToolbarTemplate.propTypes = {
  navData: PropTypes.object,
  instance: PropTypes.any,
};
