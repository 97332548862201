import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';

import { GalleryProvider } from '../../components/widgets/gallery/context/Gallery.context';
import FormWidget from '../../components/widgets/form/Form';
import GraphWidget from '../../components/widgets/graph/Graph';
import TreeGridWidget from '../../components/widgets/treegrid/Treegrid';
import ContextWidget from '../../components/widgets/contextMenu/ContextMenu';
import GalleryWidget from '../../components/widgets/gallery/raster/Gallery';
import GanttWidget from '../../components/widgets/gantt/Gantt';
import ModalDialog from '../../components/modals/Modal.dialog';
import View from '../../components/widgets/View/text/View.text';
import DiagramWidget from '../../components/widgets/diagram/Diagram';
import GridWidgetView from '../../components/widgets/grid/view/Grid.view';
import GridWidgetSort from '../../components/widgets/grid/sort/Grid.sort';
import GridWidgetLink from '../../components/widgets/grid/link/Grid.link';
import GridWidgetUpdate from '../../components/widgets/grid/update/Grid.update';
import ChatWidget from '../../components/widgets/chat/Chat';
import MapWidget from '../../components/widgets/map/Map';
import Scheduler from '../../components/widgets/scheduler/Scheduler';
import Report from '../../components/widgets/report/Report';
import CarouselWidget from '../../components/widgets/gallery/carousel/Gallery.carousel';
import KanbanComponent from '../../components/widgets/kanban/Kanban';
import CadViewer from '../../components/widgets/View/cad/View.cad';
import PDFViewer from '../../components/widgets/View/pdf/View.pdf';
import ChartWidget from '../../components/widgets/chart/Chart';
import { ViewTextProvider } from '../../components/widgets/View/text/context/View.text.context';
import { ChartProvider } from 'src/components/widgets/chart/context/Chart.context';
import { GridViewProvider } from 'src/components/widgets/grid/view/context/Grid.view.context';
import { GridSortProvider } from 'src/components/widgets/grid/sort/context/Grid.sort.context';
import { ContextMenuProvider } from 'src/components/widgets/contextMenu/context/ContextMenu.context';
import { KanbanProvider } from 'src/components/widgets/kanban/context/Kanban.context';
// import OnlyOfficeViewer from 'src/components/widgets/View/onlyofficeViewer/View.onlyoffice';

const LayoutWidget1 = ({ widget, workspaceActions }) => {
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    setCurrentPage({
      label: widget.label,
      widget: widget.widget,
      renderID: widget.key,
      params: { source: { id: null }, relation: null, target: { type: null } },
      widgetData: widget,
    });
  }, [widget, workspaceActions]);


  const commonProps = {
    actionsState: workspaceActions,
    navData: currentPage,
  };

  if (!currentPage) return null;

  const renderWidget = () => {
    
    const { widget, widgetData } = currentPage;

    switch (widget) {
      case 'Chat':
        return <ChatWidget {...commonProps} />;
      case 'Chart':
        return (
          <ChartProvider commonProps={commonProps}>
            <ChartWidget />
          </ChartProvider>
        );
      case 'Context':
        return (
          <ContextMenuProvider commonProps={commonProps}>
            <ContextWidget />
          </ContextMenuProvider>
        );
      case 'Diagram':
        return <DiagramWidget {...commonProps} />;
      case 'Form':
        return widgetData.type === 'dialog' ? (
          <ModalDialog {...commonProps} />
        ) : (
          <FormWidget {...commonProps} />
        );
      case 'Gallery':
        return widgetData.type === 'carousel' ? (
          <GalleryProvider commonProps={commonProps}>
            <CarouselWidget />
          </GalleryProvider>
        ) : (
          <GalleryProvider commonProps={commonProps}>
            <GalleryWidget />
          </GalleryProvider>
        );
      case 'Gantt':
        return <GanttWidget {...commonProps} />;
      case 'Graph':
        return <GraphWidget {...commonProps} />;
      case 'Grid':
        switch (widgetData?.type) {
          case 'view':
            return (
              <GridViewProvider commonProps={commonProps}>
                <GridWidgetView />
              </GridViewProvider>
            );
          case 'link':
            return <GridWidgetLink {...commonProps} />;
          case 'sort':
            return (
              <GridSortProvider commonProps={commonProps}>
                <GridWidgetSort />
              </GridSortProvider>
            );
          case 'update':
          case 'edit':
            return <GridWidgetUpdate {...commonProps} />;
          default:
            return null;
        }
      case 'Kanban':
        return (
          <KanbanProvider commonProps={commonProps}>
            <KanbanComponent />
          </KanbanProvider>
        );
      case 'Map':
        return <MapWidget {...commonProps} />;
      case 'Scheduler':
        return <Scheduler {...commonProps} />;
      case 'TreeGrid':
        return <TreeGridWidget {...commonProps} />;
      case 'View':
        switch (widgetData?.format) {
          case 'text':
          case 'html':
          case 'markdown':
            return (
              <ViewTextProvider commonProps={commonProps}>
                <View />
              </ViewTextProvider>
            );
          case 'file':
            // return <OnlyOfficeViewer {...commonProps} />;
            return <PDFViewer {...commonProps} />;
          case 'cad':
            return <CadViewer {...commonProps} />;
          default:
            return null;
        }
      case 'Report':
        return <Report {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
      {renderWidget()}
    </ErrorBoundary>
  );
};

LayoutWidget1.propTypes = {
  widget: PropTypes.object,
  workspaceActions: PropTypes.object,
};

export default connect()(LayoutWidget1);
